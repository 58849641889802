<template lang="html">
  <div id="home-page">

    <section class="banner-section">
      <swiper class="swiper desktop" :options="bannersOptions">
        <swiper-slide v-for="(b, bhInx) in banners.pc" :key="'bhInx-'+bhInx">
          <div class="placed-backg box" v-bind:style="{ backgroundImage: 'url('+b.imageUrl+')' }">
            <img src="public/images/pages/home/banner.png">
          </div>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>

      <swiper class="swiper mobile" :options="bannersOptions">
        <swiper-slide v-for="(b, bhmInx) in banners.movil" :key="'bhmInx-'+bhmInx">
          <div class="placed-backg box" v-bind:style="{ backgroundImage: 'url('+b.imageUrl+')' }">
            <img src="public/images/pages/home/banner-m.png">
          </div>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </section>

    <section class="benefits-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-9 col-products">
            <div class="box">
              <div class="image">
                <img src="public/images/pages/home/products.jpg">
              </div>

              <h4>Elige tu presentación favorita</h4>

              <p>
                <router-link class="btn btn-s1 btn-lg btn-lightblue" to="/productos">Realiza tu Pedido</router-link>
              </p>
            </div>
          </div>

          <div class="col-12 col-title">
            <h2 class="title-s1">Beneficios de ser clientes</h2>
          </div>

          <div class="col-sm-11 col-md-6 col-benefit">
            <div class="col col-icon">
              <i class="fal fa-home"></i>
            </div>

            <div class="col col-text">
              <h5>Agua a tu domicilio.</h5>

              <p>Visitas programadas y pedidos urgentes.</p>
            </div>
          </div>

          <div class="col-sm-11 col-md-6 col-benefit">
            <div class="col col-icon">
              <i class="fal fa-check-circle"></i>
            </div>

            <div class="col col-text">
              <h5>Servicio de calidad.</h5>

              <p>Confianza, Trato humano, Honestidad.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="trust-section">
      <div class="container">
        <swiper class="swiper" :options="trustOption">
          <swiper-slide>
            <div class="box-element">
              <div class="inside">
                <h2>"Confía.<br />La confianza en ti mismo es la clave del éxito."</h2>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </section>

    <section class="placed-backg about-us-section">
      <div class="container">
        <div class="box">
          <h2 class="title-s1">Agua Saludable, Gente Saludable</h2>

          <p class="mb-2 mb-sm-3">
            Checa y Compañía es una empresa purificadora de Agua con más de 16 años de experiencia en la ciudad de Tala, Jalisco.
          </p>
          <p class="mb-2 mb-sm-3">
            Nuestra empresa siempre se ha caracterizado por la calidad de su producto, la cercanía con el cliente y la vanguardia en procesos para lograr agua purificada segura, libre de contaminantes y baja en sales, para que tú y tu familia la puedan consumir con plena confianza.
          </p>
          <p class="mb-2 mb-sm-3">
            Checa y Compañía es una empresa ética, con valores morales que se reflejan en su equipo de trabajo, en la confianza de sus proveedores y en la preferencia del consumidor.
          </p>
          <p>
            Permítenos llegar a tu hogar y contribuir a la vitalidad y bienestar tuya y de tu familia.
          </p>
        </div>
      </div>
    </section>

    <section class="testimonials-section">
      <div class="container oversized-container testimonials-section">
        <h2 class="text-center title-s1">TESTIMONIOS</h2>

        <swiper :options="swiperOptionTop" class="gallery-top" ref="swiperTop">
          <swiper-slide v-for="(info, ttopInx) in testimonials" :key="ttopInx">
            <div class="row align-items-center">
              <div class="col-12 col-img">
                <img class="img" :src="info.imageUrl">
              </div>

              <div class="col-12 col-info">
                <h5 class="name">{{ info.name }}</h5>
                <p class="testimonial">
                  {{ info.comments }}
                </p>

                <div class="box-stars">
                  <i class="fas fa-star icon"  v-for="(st, stttopInx) in info.stars " :key="'st'+stttopInx"></i>
                </div>
              </div>
            </div>
          </swiper-slide>

          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      banners: [],

      testimonials: [],

      // == Carousel options ==
      bannersOptions: {
        loop: true,
        speed: 700,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },
      },

      trustOption: {
        loop: true,
        speed: 700,

        // autoplay: {
        //   delay: 4000,
        //   disableOnInteraction: false
        // },
      },

      swiperOptionTop: {
        spaceBetween: 10,
        loop: true,
        autoHeight: true, //enable auto height
        loopedSlides: 5, //looped slides should be the same
        slidesPerView: 2,
        spaceBetween: 30,

        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          1: {
            slidesPerView: 1,
            spaceBetween: 0
          },
        }
      },
      // == ==
    }
  },
  methods:{
    getHome(){
      axios.get(tools.url('/api/home')).then((response)=>{
        this.banners = response.data.banners;
        this.testimonials = response.data.testimonials;
      }).catch((error)=>{
        console.log(error);
      })
    },
  },
  mounted() {
      this.getHome();
  },
}
</script>
