<template lang="html">
  <div id="blog-page">

    <section class="container articles-section">
      <div class="container">
        <div class="mb-2 text-center d-block">
          <h1 class="title-page">Blog</h1>
        </div>

        <div class="row box-articles">
          <div class="col-lg-6 col-article" v-for="(b, bInx) in blogs" :key="'bInx-'+bInx">
            <router-link class="t-250 box-article-s1" :to="'/blog/'+b.id">
              <div class="placed-backg t-250 box-image" v-bind:style="{ backgroundImage: 'url('+b.imageUrl+')' }"></div>

              <div class="box-info">
                <h5 class="t-250 title">{{ b.title }}</h5>

                <div class="descr" v-html="b.body">
                  
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>
<script>
export default {
  data(){
    return{
      blogs: [],
      
    }
  },

  methods:{
    getRows(){
        axios.get(tools.url("/api/blogs")).then((response)=>{
            this.blogs = response.data;
        }).catch(()=>{});
    },
  },

  mounted(){
   
    this.getRows();
  }
}
</script>

