<template lang="html">
  <header id="header">
    <loader v-if="$root.isLoading == true" object="#346799" color1="#ffffff" color2="#ce2028" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" disableScrolling="false" name="circular"></loader>
	

    <div class="header-content">
      <b-navbar toggleable="lg" type="light" variant="">
        <div class="container oversized-container">
          <b-navbar-brand to="/">
            <img src="public/images/logo.svg">
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"><i class="far fa-bars icon"></i></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item class="simple-item" to="/nosotros">Nosotros</b-nav-item>
              <b-nav-item class="simple-item" to="/productos">Productos</b-nav-item>
              <b-nav-item class="simple-item" to="/blog">Blog</b-nav-item>
              <b-nav-item class="simple-item last" to="/contactanos">Contáctanos</b-nav-item>

              <b-nav-item-dropdown class="simple-item" right>
                <template #button-content>
                  <span><i class="fas fa-user"></i> <span class="d-lg-none">Mi cuenta</span></span>
                </template>
                <b-dropdown-item to="/login" v-if="$root.logged == false">Iniciar sesión</b-dropdown-item>
                <b-dropdown-item to="/registrarse" v-if="$root.logged == false">Registrarse</b-dropdown-item>
                <b-dropdown-item to="/usuarios" v-if="$root.logged == true">Mi cuenta</b-dropdown-item>
              </b-nav-item-dropdown>

              <li class="nav-item d-lg-none">
                <div class="nav-link">
                  <hr class="mt-0 mb-1" />
                </div>
              </li>

              <!-- <b-nav-item class="mb-1 mb-lg-0 special-item-1 gray" to="/quejas-y-sugerencias" >Quejas y Sugerencias</b-nav-item> -->
              <b-nav-item class="mb-1 nav-item special-item-1 blue" to="/cart" ><i class="fas fa-shopping-cart mr-1"></i> Mis pedidos</b-nav-item>

              <li class="nav-item nav-networks">
                <a class="nav-link" target="_blank" href="https://apps.apple.com/mx/app/agua-checa/id6670486737">
                  <img src="public/images/shared/app-store.svg" alt="App Store">
                </a>
                <a class="nav-link" target="_blank" href="https://play.google.com/store/apps/details?id=com.aguacheca.app">
                  <img src="public/images/shared/google-play.svg" alt="Google Play">
                </a>
              </li>

              <!-- <li class="nav-item nav-icon-circle">
                <div class="nav-link">
                  <router-link class="btn-network t-250 btn-con" to="/contactanos"><i class="fal fa-envelope"></i></router-link>
                  <a class="t-250 btn-network btn-fac"><i class="fab fa-facebook-f"></i></a>
                  <a class="t-250 btn-network btn-twi"><i class="fab fa-twitter"></i></a>
                </div>
              </li> -->
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
    </div>

  </header>
</template>

<script>
export default {
  data(){
    return{
    }
  },

  methods: {
  },

  mounted(){
  }
}
</script>
