<template lang="html">
  <div id="contact-page">

    <section class="container form-section">
      <div class="row justify-content-center">
        <div class="col-12 col-info">
          <h1 class="mb-3 title-s1">CONTÁCTANOS</h1>

          <p>
            <!-- Contamos con un equipo de especialistas que con gusto resolverán sus dudas y ofrecerán asesoría personalizada para consolidar cada uno de sus proyectos. -->
            ¿Quieres Distribuir nuestros productos en tu negocio?<br />
            Déjanos tus datos y algún comentario adicional. En breve uno de nuestros asesores te contactará.
          </p>
        </div>

        <div class="col-xl-10 col-form">
          <b-form @submit="onSubmit">
            <b-form-group class="custom-f-group-s1">
              <b-form-input
                v-model="form.name"
                type="text"
                placeholder="Nombre completo"
                size="lg"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group class="custom-f-group-s1">
              <b-form-input
                v-model="form.email"
                type="email"
                placeholder="Correo electrónico"
                size="lg"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group class="custom-f-group-s1">
              <b-form-input
                v-model="form.phone"
                type="text"
                placeholder="Teléfono"
                minlength="10"
                maxlength="10"
                size="lg"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group class="custom-f-group-s1">
              <b-form-input
                v-model="form.subject"
                type="text"
                placeholder="Asunto"
                size="lg"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group class="custom-f-group-s1">
              <b-form-textarea
                v-model="form.msg"
                placeholder="Mensaje"
                size="lg"
                rows="4"
                max-rows="4"
                required
              ></b-form-textarea>
            </b-form-group>

            <p class="text-center">
              <b-button type="submit" class="btn-s2">Enviar</b-button>
            </p>
          </b-form>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: null,
        email: null,
        phone: null,
        subject: null,
        msg: null,
      }
    }
  },

  methods: {
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-bottom-right',
        appendToast: true
      })
    },

    onSubmit(event) {
      event.preventDefault();

      var params = this.form;
      var apiURL = tools.url("/api/contact");

      axios.post( apiURL,params )
      .then( (response) => {
        // alert(response.data.response);
        this.makeToast('success', response.data.response, 'Mensaje enviado');
        // alert(response.data.response);

        for (let prop in this.form) {
          this.form[prop] = '';
        }
      })
      .catch( (error) => {
        // console.log(error.response.data);
        let errors = error.response.data;

        for (let prop in errors) {
          // console.log(errors[prop]);
          this.makeToast('danger', errors[prop], '¡Error!');
        }
      });
    },
  }
}
</script>
