<template lang="html">
  <div id="products-results-page">

    <section class="container oversized-container products-section">
      <div class="row">
        <div class="col-12 col-title">
          <h1 class="title">Productos</h1>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-lg-4 col-xl-3 col-product-sample-1" v-for="(p, pInx) in products" :key="'pInx-'+pInx">
          <router-link class="t-150 box-product" :to="'/producto/'+p.id">
            <div class="placed-backg image" v-bind:style="{ backgroundImage: 'url('+p.imageUrl+')' }">
              <img src="public/images/shared/empty.png">
            </div>

            <div class="description">
              <h6 class="name">{{ p.name }}</h6>

              <div class="numbers">
                <div class="price">
                  <!-- <h6 class="discount" v-if="p.oldprice">${{ p.oldprice }}</h6>
                  <h6 v-if="p.price">Desde ${{ p.price }}</h6> -->
                </div>

                <h6 class="lts">{{ p.lt }}</h6>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </section>

  </div>
</template>

<script>

export default {
  data(){
    return{
      products: [],
    }
  },

  methods:{
    getProducts(){
        axios.get(tools.url("/api/products")).then((response)=>{
            this.products = response.data;
        }).catch(()=>{});
    },
  },

  mounted(){
      this.getProducts();
  }
}
</script>

