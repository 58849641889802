<template lang="html">
  <div id="product-description-page">
    <section class="breadcrumb-section">
      <div class="container oversized-container">
        <router-link class="back" to="/productos">Regresar</router-link>
      </div>
    </section>

    <section class="container oversized-container description-section" id="product-description" v-if="isLoading == false">
      <div class="row">

        <div class="col-lg-6 col-gallery">
          <swiper :options="galleryOptions" v-if="showGallery">
            <swiper-slide v-for="(row, galIndex) in gallery" :key="galIndex">
              <div class="image-container">
                <div class="img">
                  <img :src="row">
                  <!-- <v-zoom :img="row" :width="'100%'"></v-zoom> -->
                </div>
              </div>
            </swiper-slide>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>

        <b-form class="col-lg-5 col-description" @submit="addCart">
          <h1 class="name">{{ product.name }}</h1>
          <!-- <h4 class="lts">25 Litros</h4> -->
          <!-- <h6 class="sku">Clave Articulo: {{ product.sku }}</h6> -->

          <h4 class="price" v-if="$root.logged == true">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(product.price) }}</h4>

          <h6 class="mt-4 subtitle">Descripción</h6>
          <div class="txt-description">
            {{product.description}}
          </div>

          
          <!-- <div v-if="stock != null">
            <h6 class="mt-3 subtitle" v-if="stock > 0">Stock disponible: <span style="color:#f26222; font-size: 19px">{{stock}}</span> </h6>
            <h6 class="mt-3 subtitle" v-else>Sin stock</h6>
          </div> -->

          <div class="d-block mt-4 mb-2" v-if="product.documentUrl">
            <a class="btn btn-submit btn-contact" v-if="product.documentUrl" :href="product.documentUrl" target="_blank" rel="noopener noreferrer">Descargar ficha técnica <i class="fa fa-file-pdf-o" aria-hidden="true"></i></a>
          </div>

          <!-- <h6 class="mt-2 mb-1 subtitle" v-if="product.tablaPrecios">Lista de precios</h6>
          <img class="img-fluid" :src="product.tablaPrecios"> -->

          <h6 class="mt-3 subtitle" v-if="product.delivery_time_custom && product.type == 'producto'">Tiempo de entrega</h6>
          <h4 class="delivery-time" v-if="product.delivery_time_custom && product.type == 'producto'">{{ product.delivery_time_custom }}</h4>

          <h4 class="delivery-time" v-if="product.type == 'servicio'">El proveedor de este servicio se coordinará con el comprador para definir fecha y hora de inicio del servicio</h4>

          <div class="col-quantity2 mt-3" v-if="product.stock > 0">
            <a class="form-control btn-q" @click="changeCantidad('-')">
              <i class="fas fa-minus"></i>
            </a>
            <b-form-input class="input-q" type="number" min="0" v-model="form.quantity" @keypress="isNumber($event)" @paste="noPaste" />
            <a class="form-control btn-q" @click="changeCantidad('+')">
              <i class="fas fa-plus"></i>
            </a>
          </div>

          <div class="d-block mt-4 mb-2">
            <b-button type="submit" class="my-2 my-xl-0 btn-submit">Agregar a pedido</b-button>
          </div>
        </b-form>
      </div>
    </section>

    <sweet-modal blocking hide-close-button  ref="modalloading">
      <div>Cargando ...</div>
    </sweet-modal>

    <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
      <div v-html="modal.msg"></div>
      <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
        <b-button class="btn btn-primary" slot="button" v-on:click.prevent="$refs.modal.close()">Agregar mas productos</b-button>
        <b-button class="btn btn-primary" slot="button" v-on:click.prevent="$refs.modal.close(); $router.push('/cart')">Ir al carrito <i class="color-white fas fa-shopping-cart"></i></b-button>
      </div>
    </sweet-modal>
  </div>
</template>

<script>


import vZoom from 'vue-zoom'; // https://www.npmjs.com/package/vue-zoom
export default {
  components: {vZoom},

  data() {
    return {
      id: null,
      showGallery: true,
      modalGallery: [],
      showModalGallery: false,

      form: {
        modelo: null,
        quantity: 1,
        color: null,
        models:[],
        modelo:null
      },

      product: {},

      category: [],

      gallery: [ ],

      // isLoading: true,
      isLoading: false,

      modal:{
        msg:'',
        icon:'',
        block:false
      },

      // == Carrusel options ==
      galleryOptions: {
        loop: false,
        speed: 600,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }

        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false
        // }
      },
      // == ==
    }
  },

    watch: {
        "$route.params.id" : function(v){
            //this.id = this.$route.params.id;
            this.id = this.$root._getURLID(this.$route.params.id);
            this.getProduct();
        },
    },

    methods:{
        getProduct(){
            this.$root.isLoading = true; // DESCOMENTAR ESTO
            this.showGallery = false; // DESCOMENTAR ESTO
            axios.get(tools.url('/api/product/' + this.id)).then((response)=>{
                this.product = [];
                this.gallery = [];

                this.product = response.data;
                this.gallery = response.data.gallery;
                this.category = response.data.category;

                this.showGallery = true;
                this.getRelated(this.id);

                this.$root.isLoading = false;
            }).catch((error)=>{
                console.log(error);
                this.$root.isLoading = false;
            });
        },

        getRelated(id){
        axios.get(tools.url('/api/products/related/'+id)).then((response)=>{
            this.products = response.data;

        }).catch((error)=>{
            console.log(error);
        })
        },

        addCart(evt){
            evt.preventDefault();
            var cart = [];
            if(localStorage.cart){
                cart = JSON.parse(localStorage.getItem('cart'));
                for(var i = 0; i < cart.length ; i++){
                    if(cart[i].id == this.id ){
                        var suma = cart[i].quantity + this.form.quantity;
                        cart[i].quantity = suma <= this.product.stock ? suma : this.product.stock;
                        encontrado = true;
                        break;
                    }
                }
                if(!encontrado){
                    cart.push({ id:this.id, quantity:this.form.quantity});
                }
            }
            else{
                cart.push({ id:this.id, quantity:this.form.quantity});
            }
            

            var encontrado = false;
            localStorage.setItem("cart",JSON.stringify(cart));
            this.$root.cartCount = cart.length;
            //this.toast();
            this.modal.msg = 'El producto <b>'+this.product.name+'</b> se agregado al carrito';
            this.modal.icon = 'success';
            this.$refs.modal.open();
        },
        noPaste(evt){
            event.preventDefault();
        },

        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if (charCode < 48 || charCode > 57) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        changeCantidad($type){
            this.form.quantity = parseInt(this.form.quantity);
            if ($type == '-') {
                this.form.quantity = (this.form.quantity > 1) ? --this.form.quantity : 1;
            }
            else{
                this.form.quantity = (this.form.quantity < 100) ? ++this.form.quantity : 100;
            }
        },
    },

    mounted(){
        this.id = this.$root._getURLID(this.$route.params.id);
        this.getProduct();
    }
}
</script>
