<template lang="html">
  <div id="article-page">

    <section class="header-banners-section-s2">
    </section>

    <section class="container main-section">
      <div class="row">
        <div class="col-lg-7 mt-3 mt-lg-0 col-content">
          <div class="mb-2">
            <h1 class="title-s1">{{row.titlw}}</h1>
          </div>

          <div class="box-content" v-html="row.body">
            
          </div>
        </div>

        <div class="col-lg-5 col-image">
          <div class="placed-backg image" v-bind:style="{ backgroundImage: 'url('+row.imageUrl+')' }">
            <img src="public/images/shared/empty.png">
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      row: {},
      id:null
    }
  },

  methods:{
    getRow(){
        axios.get(tools.url("/api/blogs/"+this.id)).then((response)=>{
            this.row = response.data;
        }).catch(()=>{});
    },
  },

  mounted(){
    this.id = this.$root._getURLID(this.$route.params.id);
    this.getRow();
  }
}
</script>

